import React, { useEffect, useState } from "react"

// Layout
import Layout from "layouts/layout-primary"

// Libraries
import styled from "styled-components"
import Lottie from "react-lottie"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import ContextConsumer from "components/context/"
import Container from "components/container/"
import LinkWithIcon from "components/link-with-icon/"

// Animation
import AnimationOne from "assets/animations/404/404-animation-1.json"

const StyledContainer = styled(Container)`
  ${breakpoint.medium`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

const StyledNotFound = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 120px 0;
  display: flex;
  align-items: center;
  background-color: ${colors.midnight};
  color: ${colors.white};

  .content {
    max-width: 450px;
    margin-bottom: 64px;

    ${breakpoint.medium`
      margin: 0;
    `}
  }

  .illustration {
    max-width: 544px;
  }

  h2 {
    color: ${colors.supernova};
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 64px;
    `}
  }
`

const Animations = [AnimationOne, AnimationOne]

const NotFound = () => {
  const [animation, setAnimation] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    setAnimation(Animations[Math.floor(Math.random() * Animations.length) + 0]);
  },[]);

  const options = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <Layout menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <StyledNotFound>
        <StyledContainer>
          <div className="content">
            <h1>
              <Trans>Oops!</Trans>
            </h1>
            <h2>
              <Trans>Seems like you've hit a dead end.</Trans>
            </h2>

            <LinkWithIcon
              to="/"
              text={t("We'll guide you home")}
              iconColor={colors.indiblue}
            />
          </div>

          <div className="illustration">
            <Lottie options={options} isClickToPauseDisabled={true} />
          </div>
        </StyledContainer>
      </StyledNotFound>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFound
